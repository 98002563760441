/**
 * @generated SignedSource<<c5c1169a850a31a1aad1d37172128ed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ContentUsageInstanceItemQuery$variables = {
  id: string;
};
export type ContentUsageInstanceItemQuery$data = {
  readonly module: {
    readonly id?: string;
    readonly name?: string | null;
    readonly product?: {
      readonly badge: {
        readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
      } | null;
      readonly name: string;
      readonly registrationAvailability: ProductRegistrationAvailability;
    } | null;
    readonly usages?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly collection: {
            readonly app: {
              readonly badge: {
                readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
              };
              readonly customAppTitle: string | null;
            };
          } | null;
          readonly id: string;
          readonly isDefaultCollectionModule: boolean;
        };
      }>;
    };
  } | null;
  readonly productApp: {
    readonly id?: string;
    readonly kind?: ProductAppKind;
  } | null;
};
export type ContentUsageInstanceItemQuery = {
  response: ContentUsageInstanceItemQuery$data;
  variables: ContentUsageInstanceItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationAvailability",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BadgeFragment"
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefaultCollectionModule",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentUsageInstanceItemQuery",
    "selections": [
      {
        "alias": "module",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsageNodeConnection",
                "kind": "LinkedField",
                "name": "usages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Collection",
                            "kind": "LinkedField",
                            "name": "collection",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductApp",
                                "kind": "LinkedField",
                                "name": "app",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Content",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "productApp",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "type": "ProductApp",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentUsageInstanceItemQuery",
    "selections": [
      {
        "alias": "module",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsageNodeConnection",
                "kind": "LinkedField",
                "name": "usages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Collection",
                            "kind": "LinkedField",
                            "name": "collection",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductApp",
                                "kind": "LinkedField",
                                "name": "app",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v10/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Content",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "productApp",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v8/*: any*/)
            ],
            "type": "ProductApp",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79fd47e756b03bac920fd09b170d4509",
    "id": null,
    "metadata": {},
    "name": "ContentUsageInstanceItemQuery",
    "operationKind": "query",
    "text": "query ContentUsageInstanceItemQuery(\n  $id: ID!\n) {\n  module: node(id: $id) {\n    __typename\n    ... on Content {\n      id\n      name\n      product {\n        name\n        registrationAvailability\n        badge {\n          ...BadgeFragment\n          id\n        }\n        id\n      }\n      usages {\n        edges {\n          node {\n            id\n            isDefaultCollectionModule\n            collection {\n              app {\n                customAppTitle\n                badge {\n                  ...BadgeFragment\n                  id\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  productApp: node(id: $id) {\n    __typename\n    ... on ProductApp {\n      id\n      kind\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n"
  }
};
})();

(node as any).hash = "7fc9d97ea1808df8cd75b93e0b22eb8e";

export default node;
